<template>
  <div class="toast show additional-block" :style="'background:' + task.type_state.custom_color.replace('0.5', '0.1')">
    <router-link v-if="([1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
                    $store.getters['auth/authUser'].id === task.responsible_id) && task.client &&
                    task.details && task.details.mark && task.details.model && task.details.year"
                 :to="{ name: 'car.create', params: {
                    currentCarData: task.details,
                    currentClientItem: task.client,
                 }}"
                 class="btn btn-info"
                 title="Добавить авто в систему"
                 type="button">
      <i class="fa-solid fa-car-on"></i>
    </router-link>

    <button v-if="[1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
                $store.getters['auth/authUser'].id === task.responsible_id"
            class="btn btn-success margin-l-5"
            title="Перенести в Продажи"
            type="button"
            v-on:click="moveTaskToMain(task)">
      <i class="fa-solid fa-boxes-packing"></i>
    </button>
  </div>
</template>


<script>
import Form from "vform";

export default {
  name: 'task-call-center-item-additional-actions',

  props: {
    task: {
      type: Object,
      required: true
    },
  },

  methods: {
    moveTaskToMain(task) {
      this.$dialog.confirm('Вы уверены что хотите перенести задачу?', this.$dialogOptions).then(() => {
        new Form({
          client_id: task.client_id,
          type_id: 1,
          type_state_id: null,
          type_status_id: 1,
          details: task.details,
          responsible_id: null,
        }).put('/tasks/edit/' + task.id).then(() => {
          showSuccess();
          this.EventBus.$emit('task-items-reload');
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  },
}
</script>

<style scoped>
.toast {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  width: unset;
  box-shadow: none;
  margin-bottom: 15px;
}

.additional-block {
  border-top: none;
  margin-top: -15px;
  padding: 8px;
}

</style>
