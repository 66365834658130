var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toast show additional-block",style:('background:' + _vm.task.type_state.custom_color.replace('0.5', '0.1'))},[(([1, 2, 7, 8, 9].includes(_vm.$store.getters['auth/authUser'].role.id) ||
                  _vm.$store.getters['auth/authUser'].id === _vm.task.responsible_id) && _vm.task.client &&
                  _vm.task.details && _vm.task.details.mark && _vm.task.details.model && _vm.task.details.year)?_c('router-link',{staticClass:"btn btn-info",attrs:{"to":{ name: 'car.create', params: {
                  currentCarData: _vm.task.details,
                  currentClientItem: _vm.task.client,
               }},"title":"Добавить авто в систему","type":"button"}},[_c('i',{staticClass:"fa-solid fa-car-on"})]):_vm._e(),([1, 2, 7, 8, 9].includes(_vm.$store.getters['auth/authUser'].role.id) ||
              _vm.$store.getters['auth/authUser'].id === _vm.task.responsible_id)?_c('button',{staticClass:"btn btn-success margin-l-5",attrs:{"title":"Перенести в Продажи","type":"button"},on:{"click":function($event){return _vm.moveTaskToMain(_vm.task)}}},[_c('i',{staticClass:"fa-solid fa-boxes-packing"})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }